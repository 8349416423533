<template>
  <div>
    <v-card-text>
      <v-alert v-if="!documentReady" text type="info"
        >No hay data para mostrar</v-alert
      >
      <div outlined class="mb-2" v-if="documentReady">
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-linear>
        <iframe
          value="frameID"
          style="border: 2px solid rgb(237, 237, 237)"
          width="100%"
          :height="window.height"
          :src="urlReportViewer"
          allowfullscreen
        ></iframe>
      </div>

      <v-fab-transition>
        <v-btn
          v-if="$vuetify.breakpoint.mobile"
          color="transparent"
          absolute
          top
          right
          fab
          fixed
          style="top: 120px; right: 0px"
          @click.native="drawerRight = !drawerRight"
        >
          <v-icon>filter_alt</v-icon>
        </v-btn>
      </v-fab-transition>

      <v-navigation-drawer v-model="drawerRight" app clipped fixed right>
        <v-card-text>
          <div class="subtitle-1 py-5">Filtros</div>
          <v-divider></v-divider>
          <br />
          <v-btn-toggle v-model="tipo" borderless dense group color="info">
            <v-btn value="individual" text>Masiva</v-btn>
            <v-btn value="masiva" text>Archivo</v-btn>
          </v-btn-toggle>
          <v-divider></v-divider>
          <div v-if="tipo == 'individual'">
            <v-form ref="formIndividual" lazy-validation>
              <v-row wrap>
                <v-col cols="12">
                  <div style="padding: 0px">
                    <v-autocomplete
                      :items="sucursalesVisibles"
                      :filter="customFilter"
                      v-model="sucursalesSelected"
                      item-text="CodNombre"
                      item-value="CanalSucursalId"
                      label="Sucursales"
                      color="red"
                      append-icon="keyboard_arrow_down"
                      hint="Dejar vacio para filtrar por todas"
                      multiple
                      chips
                      deletable-chips
                      persistent-hint
                      clearable
                    ></v-autocomplete>
                    <v-checkbox
                      label="Solo sucursales activas"
                      v-model="soloActivas"
                      color="red"
                      style="margin-top: 0px; height: 20px"
                    ></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    :items="tipoProducto"
                    :filter="customFilter"
                    v-model="tipoproductosSelected"
                    item-text="ProductoName"
                    item-value="ProductoTipoId"
                    label="Tipos productos"
                    hint="Dejar vacio para filtrar por todos"
                    append-icon="keyboard_arrow_down"
                    multiple
                    chips
                    deletable-chips
                    persistent-hint
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    :items="listProductos"
                    :filter="customFilter"
                    v-model="productosSelected"
                    item-text="ProductName"
                    item-value="ProductId"
                    label="Productos"
                    hint="Dejar vacio para filtrar por todos"
                    append-icon="keyboard_arrow_down"
                    multiple
                    chips
                    deletable-chips
                    persistent-hint
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    :items="listProductoCodes"
                    :filter="customFilter"
                    v-model="productocodesSelected"
                    item-text="ProductCode"
                    item-value="ProductCodeID"
                    label="Price plan"
                    hint="Dejar vacio para filtrar por todos"
                    append-icon="keyboard_arrow_down"
                    multiple
                    chips
                    deletable-chips
                    persistent-hint
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="Serie"
                    :counter="15"
                    label="Serie (IMEI)"
                    hint="Serie (IMEI) del equipo"
                    maxlength="15"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="Simcard"
                    label="SIMCARD"
                    hint="SIMCARD"
                    maxlength="20"
                    :counter="20"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <date-selector
                    label="Fecha desde"
                    :fecha.sync="fechaInicio"
                    :rules="dateRule"
                  ></date-selector>
                </v-col>
                <v-col cols="12">
                  <date-selector
                    label="Fecha hasta"
                    :fecha.sync="fechaFin"
                    :rules="dateRule"
                  ></date-selector>
                </v-col>
                <v-col cols="12">
                  <v-radio-group
                    v-model="formato"
                    hide-details
                    style="margin: 0px"
                  >
                    <template v-slot:label>
                      <div>Formato resultado</div>
                    </template>
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-radio
                          label="Tabla"
                          value="1"
                          v-on="on"
                          color="red"
                        ></v-radio>
                      </template>
                      <span>
                        <v-card>
                          <v-card-title>Tabla</v-card-title>
                          <div>
                            <img src="@/assets/tabular.png" alt />
                          </div>
                        </v-card>
                      </span>
                    </v-tooltip>
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-radio
                          label="Matrix"
                          value="2"
                          v-on="on"
                          color="red"
                        ></v-radio>
                      </template>
                      <span>
                        <v-card>
                          <v-card-title>Matrix</v-card-title>
                          <div>
                            <img src="@/assets/matrix.png" alt />
                          </div>
                        </v-card>
                      </span>
                    </v-tooltip>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" v-if="this.formato === '1'">
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        :items="columns"
                        :filter="customFilter"
                        v-model="columnsSelected"
                        :rules="columnRule"
                        item-text="column"
                        item-value="value"
                        color="red"
                        append-icon="keyboard_arrow_down"
                        hint="Seleccionar columnas que desea visualizar o eliminar dando click a la X del texto"
                        multiple
                        chips
                        deletable-chips
                        persistent-hint
                        clearable
                        required
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <div class="text-right">
                        <v-btn
                          outlined
                          class="ma-1"
                          depressed
                          color="primary"
                          @click="guardarColumnas"
                          >Guardar</v-btn
                        >
                        <v-btn
                          class="ma-1"
                          depressed
                          color="accent"
                          @click="getColumnas"
                          >Restaurar</v-btn
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </div>
          <div v-if="tipo == 'masiva'">
            <v-form ref="formMasiva" lazy-validation>
              <v-row wrap>
                <v-col cols="12">
                  <br />
                  <v-file-input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    counter
                    v-model="file"
                    chips
                    placeholder="Seleccionar archivo"
                    :show-size="1000"
                    :rules="fileRule"
                    style="padding: 0px"
                  ></v-file-input>
                  <a target="_blank" href="static/BusquedaMasiva.xlsx"
                    >Descargar plantilla</a
                  >
                </v-col>
                <v-col cols="12">
                  <v-radio-group
                    v-model="formato"
                    style="margin: 0px"
                    hide-details
                  >
                    <template v-slot:label style="margin: 0px">
                      <div>Formato resultado</div>
                    </template>
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-radio
                          label="Tabla"
                          value="1"
                          v-on="on"
                          color="red"
                        ></v-radio>
                      </template>
                      <span>
                        <v-card>
                          <v-card-title>Tabla</v-card-title>
                          <div>
                            <img src="@/assets/tabular.png" alt />
                          </div>
                        </v-card>
                      </span>
                    </v-tooltip>
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-radio
                          label="Matrix"
                          value="2"
                          v-on="on"
                          color="red"
                        ></v-radio>
                      </template>
                      <span>
                        <v-card>
                          <v-card-title>Matrix</v-card-title>
                          <div>
                            <img src="@/assets/matrix.png" alt />
                          </div>
                        </v-card>
                      </span>
                    </v-tooltip>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" v-if="formato === '1'">
                  <v-autocomplete
                    :items="columns"
                    :filter="customFilter"
                    v-model="columnsSelected"
                    item-text="column"
                    item-value="value"
                    multiple
                    chips
                    deletable-chips
                    hint="Seleccionar columnas que desea visualizar o eliminar dando click a la X del texto"
                    persistent-hint
                    append-icon="keyboard_arrow_down"
                    required
                    clearable
                    :rules="columnRule"
                  ></v-autocomplete>
                  <div class="text-right">
                    <v-btn
                      outlined
                      class="mr-1"
                      depressed
                      color="primary"
                      @click="guardarColumnas"
                      >Guardar</v-btn
                    >
                    <v-btn
                      class="mr-1"
                      depressed
                      color="accent"
                      @click="getColumnas"
                      >Restaurar</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
          <br />
          <div class="mb-5 text-right">
              <v-btn block class="ma-1" depressed color="primary" @click="buscar">
                <v-icon left>search</v-icon>Buscar
                <span slot="loader">Buscando...</span>
              </v-btn>
              <v-progress-linear
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-linear>
            </div>
        </v-card-text>
      </v-navigation-drawer>
    </v-card-text>
  </div>
</template>

<script>
import apiReporte from "@/api/reportes";
import download from "downloadjs";
import { global } from "@/api/global";
import apiAsociado from "@/api/asociados";
import { mapActions } from "vuex";
import apiProducto from "@/api/productos";

let _baseurl = global.baseWebUrl + "mvc/Report/";

export default {
  data: () => {
    return {
      drawerRight: true,
      filter: true,
      tipo: "individual",
      soloActivas: true,
      loading: false,
      file: [],
      formato: "1",
      fechaInicio: null,
      fechaFin: null,
      dateRule: [(v) => !!v || "Seleccionar una fecha"],
      columnRule: [(v) => !!v || "Seleccionar al menos una columna"],
      fileRule: [
        (v) =>
          (v && v.size < 4 * 1000 * 1000) ||
          "El archivo que esta intentando subir excede los 4 MB",
      ],
      sucursalesSelected: "",
      tipoproductosSelected: "",
      productosSelected: "",
      productocodesSelected: "",
      listProductos: [],
      listProductoCodes: [],
      Serie: "",
      Simcard: "",
      customFilter(item, queryText) {
        const hasValue = (val) => (val != null ? val : "");
        const text =
          hasValue(item.CodNombre) ||
          hasValue(item.ProductoName) ||
          hasValue(item.ProductName) ||
          hasValue(item.ProductCode) ||
          hasValue(item.column);
        const query = hasValue(queryText);
        return (
          text
            .toString()
            .toLowerCase()
            .indexOf(query.toString().toLowerCase()) > -1
        );
      },
      urlReportViewer: global.baseWebUrl + "loader.html",
      heightSize: "436px",
      documentReady: false,
      columnsSelected: [],
      columns: [
        { column: "CodSucursal", value: "CodSucursal" },
        { column: "Componente", value: "Componente" },
        { column: "Concepto", value: "Concepto" },
        { column: "Empleado", value: "Empleado" },
        { column: "Fecha Activacion", value: "FechaActivacion" },
        { column: "Fecha Cancelacion", value: "FechaCancelacion" },
        { column: "Fecha Transaccion", value: "FechaTransaccion" },
        { column: "ItemID IMEI", value: "ItemIDSer" },
        { column: "ItemID SIMCARD", value: "ItemIDSim" },
        { column: "Monto", value: "Monto" },
        { column: "Num. Transaccion", value: "NumTransaccion" },
        { column: "PriceCode", value: "PriceCode" },
        { column: "PricePlan", value: "PricePlan" },
        { column: "ReasonCodeCan", value: "ReasonCodeCan" },
        { column: "Serie (IMEI)", value: "Serie" },
        { column: "SIMCARD", value: "Simcard" },
        { column: "Sucursal", value: "NombreSucursal" },
        { column: "Telefono", value: "Telefono" },
        { column: "Tipo Producto", value: "TipoProducto" },
        { column: "Tipo Cancelacion", value: "TipoCancelacion" },
        { column: "Ultimo Estado", value: "UltimoEstado" },
      ],
      window: {
        width: 0,
        height: 0,
      },
      breadcrumb: [
        {
          text: "Comisiones",
          disabled: true,
        },
        {
          text: "Consulta Masiva",
          disabled: true,
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    tipoProducto() {
      return this.$store.getters.tipoProducto;
    },
    sucursalesVisibles() {
      if (this.soloActivas) {
        return this.$store.getters.user.sucursales.filter(
          (sucursal) => sucursal.BEnable && !sucursal.EndDate
        );
      } else {
        return this.$store.getters.user.sucursales;
      }
    },
    cortes() {
      return this.$store.getters.cortes;
    },
    formFiltrosIsValid() {
      return this.fechaInicio !== null && this.fechaFin !== null;
    },
    formMasivaIsValid() {
      return this.fileNombre !== "";
    },
    error() {
      return this.$store.getters.error;
    },
    errormessage() {
      return this.$store.getters.errormessage;
    },
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    stop() {},
    onDismissed() {
      this.$store.dispatch("clearError");
    },
    buscar() {

      let _formato = null;
      if (this.formato === "1") {
        _formato = "Tabla";
      }
      if (this.formato === "2") {
        _formato = "Matriz";
      }
      if (this.formato === "3") {
        _formato = "Estandar";
      }

      if (this.tipo === "individual" && this.$refs.formIndividual.validate()) {
        this.documentReady = true;
        this.urlReportViewer = global.baseWebUrl + "loader.html";

        this.heightSize = "436px";
        var self = this;
        setTimeout(function () {
          self.urlReportViewer =
            _baseurl +
            "ReportViewer?" +
            "NombreReporte=MovimientosActivaciones" +
            _formato +
            "&Busqueda=Filtro" +
            "&AsociadoID=" +
            self.user.asociado.id +
            "&Sucursales=" +
            self.sucursalesSelected +
            "&TipoProductos=" +
            self.tipoproductosSelected +
            "&Productos=" +
            self.productosSelected +
            "&Serie=" +
            self.Serie +
            "&Simcard=" +
            self.Simcard +
            "&FechaDesde=" +
            self.fechaInicio +
            "&FechaHasta=" +
            self.fechaFin +
            "&ProductCode=" +
            self.productocodesSelected +
            "&Columnas=" +
            self.columnsSelected;
        }, 2000);
      } else {
        if (this.$refs.formMasiva.validate()) {
          this.documentReady = false;
          this.loading = true;

          let data = new FormData();
          data.append("NombreReporte", "MovimientosActivaciones" + _formato);
          data.append("Busqueda", "Archivo");
          data.append("AsociadoID", this.user.asociado.id);
          data.append("Archivos", this.file);
          data.append("Columnas", this.columnsSelected);
          apiReporte
            .getReporteMasivasArchivo(data)
            .then((response) => {
              download(response.data, "ResultadosMasivas" + ".xlsx");

              this.loading = false;
              this.documentReady = false;
              this.$store.dispatch("clearError");
              this.$store.dispatch("setErrorMessage", null);
            })
            .catch((error) => {
              this.loading = false;
              this.documentReady = false;
              this.$store.dispatch("setError", error);
              this.$store.dispatch("setErrorMessage", error.message);
            });
        }
      }

    },
    getColumnas() {
      apiAsociado
        .getColumnas()
        .then((response) => {
          if (response.data === null) {
            this.columnsSelected = [
              "CodSucursal",
              "Componente",
              "Concepto",
              "Empleado",
              "FechaActivacion",
              "FechaCancelacion",
              "FechaTransaccion",
              "NombreSucursal",
              "ItemIDSer",
              "ItemIDSim",
              "Monto",
              "NumTransaccion",
              "PriceCode",
              "PricePlan",
              "ReasonCodeCan",
              "Serie",
              "Simcard",
              "Telefono",
              "TipoCancelacion",
              "TipoProducto",
              "UltimoEstado",
            ];
          } else {
            let dataStr = response.data;
            let columnas = dataStr.split(",");
            this.columnsSelected = columnas;
          }
        })
        .catch((error) => {
          this.setError(error);
        });
    },
    guardarColumnas() {
      if (
        Array.isArray(this.columnsSelected) &&
        this.columnsSelected.length > 0
      ) {
        apiAsociado
          .guardarColumnas(this.columnsSelected)
          .then(() => {
            let mensaje = {
              title: "Satisfactorio",
              body: "Se han guardado las columnas seleccionadas",
              type: "success",
            };
            this.setMessage(mensaje);
          })
          .catch((error) => {
            this.setError(error);
          });
      } else {
        let mensaje = {
          title: "Requerido",
          body: "Debe seleccionar al menos una columna",
          type: "warning",
        };
        this.setMessage(mensaje);
      }
    },
    refreshProductos() {
      apiProducto
        .GetProductosByProductoTipoID(this.tipoproductosSelected)
        .then((response) => {
          this.listProductos = response.data;
        })
        .catch((error) => {
          this.setError(error);
        });
    },
    refreshProductCodes() {
      apiProducto
        .GetProductCodesByProductoID(this.productosSelected)
        .then((response) => {
          this.listProductoCodes = response.data;
        })
        .catch((error) => {
          this.setError(error);
        });
    },
    refreshProductCodes2() {
      apiProducto
        .GetProductCodesByTipoID(this.tipoproductosSelected)
        .then((response) => {
          this.listProductoCodes = response.data;
        })
        .catch((error) => {
          this.setError(error);
        });
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight - 240;
    },
  },
  watch: {
    tipoproductosSelected: function () {
      this.refreshProductos();
      this.refreshProductCodes2();
    },
    productosSelected: function () {
      this.refreshProductCodes();
    },
  },
  mounted() {
    this.getColumnas();
    this.refreshProductos();
    this.refreshProductCodes();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style scoped>
.v-input__prepend-outer {
  display: none;
}
</style>